import React,{useState,useEffect} from "react";
import Footer from "../nav/Footer";
import { JSON_BROKER,API_URL } from '../config';
import { Credentials } from "../login/fn_login";
import './broker.css';
import Loader from "../editor/loader";

export default function Broker(){
    const [firstRender,setFirstRender] = useState(false);
    const [data,setData] = useState({h1:'', h2:'', h1_content:'', h2_content:[]});
    const [status,setStatus] = useState(0); // 0: view. 1: edit, 2: need saving
    const [loading,setLoading] = useState(false);

    useEffect(() => { setFirstRender(true); },[]);
    useEffect(() => {
        if(firstRender){
            getData();
            setFirstRender(false);
        }
    },[firstRender]);

    const getData = async () => {
        const req =  await fetch(JSON_BROKER, {cache:'no-cache'});
        const reqDat = await req.json();
        setData(reqDat);
    }

    const saveData = async () => {
        setLoading(true);
        const itm = JSON.stringify(data);
        var fd = new FormData();
        fd.append('jsonUrl', JSON_BROKER);
        fd.append('token', Credentials.token());
        fd.append('document',itm);
        const req = await fetch(API_URL + '/document', {method:'post',mode:'cors',body:fd});
        if(req.ok)
        {
            getData();
            setStatus(0);
        }
        else
            alert('Could not save! Check debug for errors.');
        setLoading(false);
    }

    const setEditState = () => {
        setStatus(1);
        setQuestion('');
        setAnswer('');
    }

    const changeKey = (key,value, index = -1) => {
        if(index === -1)
            setData(d => { var d2 = {...d};d2[key] = value;return d2; });
        else
            setData(d => {
                var d2 = {...d};
                d2['h2_content'][index][key] = value;
                return d2;
            });
        if(status === 1) setStatus(2);
    }

    // add new question
    const [question,setQuestion] = useState('');
    const [answer,setAnswer] = useState('');
    
    const addQuestion = () => {
        var qst = question, ans = answer;
        setData(d => {
            var d2 = {...d};
            var d2a = [...d2['h2_content']];
            d2a.push({q:qst, a:ans});
            d2['h2_content'] = d2a;
            return d2;
        });
        if(status === 1) setStatus(2);
        setQuestion('');
        setAnswer('');
    }


    const delQuestion = (index) => {
        setData(d => {
            var d2 = {...d};
            var d2a = [...d2['h2_content']];
            d2a.splice(index, 1);
            d2['h2_content'] = d2a;
            return d2;
        });
        if(status === 1) setStatus(2);
    }

    return (
        <div className="height-stretch broker">

            <div className="navbar-height" />

            <div className="height-grow width-fit box box-opacity Y-margin">

                {loading && <Loader />}

                {/* VIEW: mainTitle and edit-button */}
                {status === 0 && <h1>{data.h1 || 'Titel'} {Credentials.isValid() && <button className="icon edit" onClick={() => setEditState()} />}</h1> }

                {/* TOOLS: button field */}
                {status !== 0 && 
                <div className="icon-wrapper">
                    {status === 2 && <button className="icon save" title='SAVE' onClick={() => saveData()} />}
                    <button className="icon cancel" onClick={() => {setStatus(0);getData();}} title='CANCEL' />
                </div>
                }

                {/* EDIT: mainTitle */}
                {status !== 0 && <div className="input-wrapper">
                    <input type='text' onChange={(e) => changeKey('h1',e.target.value)} className="input-title" value={data.h1 || ''} />
                </div>}
                
                {/* VIEW: Page Text */ status === 0 &&
                <div className="box box-white X-margin box-border text"> 
                    {data.h1_content && data.h1_content.split('\n').map((item, ix) => <p key={ix}>{item}</p>)} 
                </div>
                }

                {/* EDIT: Page Text */ status !== 0 &&
                <div className="box X-margin">
                    <textarea className="input-text" onChange={(e) => changeKey('h1_content',e.target.value)} value={data.h1_content || ''} />
                </div>
                }

   
                {/* SECTION QUESTIONS */}
                <br /><br />

                {/* VIEW/EDIT: QnA title */ status === 0 ? 
                    <h2 style={{textAlign:'center',paddingBottom:20}}>{data.h2 || ''}</h2>
                    :
                    <div className="input-wrapper">
                        <input type='text' onChange={(e) => changeKey('h2',e.target.value)} className="input-title" value={data.h2 || ''} />
                    </div>
                }


                <div className="box box-white X-margin box-border">

                    <br /><br />

                    {/* EDIT: Form for new question */ status !== 0 && 
                    <>
                    <div className="qna title">NY FRÅGA</div>
                    <div className="qna body">
                        <div className="qna">
                            <div>
                                <input type='text' placeholder="Ny fråga...?" value={question} onChange={(e) => setQuestion(e.target.value)} />
                                <textarea placeholder="Ett svar..." value={answer} onChange={(e) => setAnswer(e.target.value)} />
                            </div>
                            <button className="icon add" onClick={() => addQuestion()} title='ADD' disabled={question.length === 0 || answer.length===0} />
                        </div>
                    </div>
                    <br /><br />
                    </>
                    }

                    {// VIEW/EDIT: Questions
                        data.h2_content && 
                        data.h2_content.map((item, index) => 

                            // a question and answer
                            <div key={index} className='qna' style={{paddingBottom:20}}>
                                {status === 0 ? 
                                    <div>
                                        <h3>{item.q}</h3>
                                        <div>
                                            {item.a.split('\n').map((a,i) => <p className="answer" key={'a'+i.toString()}>{a}</p>)}
                                        </div>
                                    </div>
                                :
                                    <>
                                    <div>
                                        <input type='text' value={item.q} onChange={(e) => changeKey('q',e.target.value, index)} />
                                        <textarea value={item.a} onChange={(e) => changeKey('a',e.target.value,index)} />
                                    </div>
                                    <button className="icon delete" onClick={() => delQuestion(index)} title='REMOVE' />
                                    </>
                                }
                            </div>
                        ) // map-end
                    }


                </div>

                <br /><br />
            </div>

            <Footer />

        </div>
    );
}
