import React, {useState,useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import Footer from '../nav/Footer'
import './info.css'
import { JSON_FILES } from '../config'
import { isoDateString } from '../editor/functions'
import { Credentials} from '../login/fn_login'


export default function Info(){
    const navigate = useNavigate();
    const [tags,setTags] = useState([]);
    const [data,setData] = useState([]);
    const [grid,setGrid] = useState([]);
    const [search,setSearch] = useState('');
    const [sort,setSort] = useState({name:"", direction:"asc"});

    useEffect(() => {
        getData();
    },[]);
    useEffect(() => {
        var _tags = tags.filter(x => x.enabled).map(x => x.name);
        setGrid(getFilteredData(_tags,search));
    } ,[data])

    
    //const SORT_NEWS = (a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime();}
    
    const handleSort = (id) => {
        var sitem = {...sort};
        if(sitem.name === id)
            sitem.direction = sitem.direction === 'asc' ? 'desc' : 'asc';
        else
            sitem = {name:id, direction:'asc'};
    
        var data2 = [...data];
        data2.sort((a,b) => {
            if(sitem.name === 'date')
            {
                if(sitem.direction ==='asc') return new Date(b.modified).getTime() - new Date(a.modified).getTime();
                else return new Date(a.modified).getTime() - new Date(b.modified).getTime();
            }
            else
            {
                if(sitem.direction === 'asc') return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0);
                if(sitem.direction === 'desc') return a.name > b.name ? -1 : (a.name < b.name ? 1 : 0);
            }
            return 0;
        });

        // måste sortera på grid,,,, ä fördröjningar i exe
        setData(data2);
        setSort(sitem);
    }

    const getData = async () => {
        const sort_init = (a,b) => { return new Date(b.modified).getTime() - new Date(a.modified).getTime();}
        const response = await fetch(JSON_FILES,{cache:'no-cache'});
        if(response.ok)
        {
            try{
                var rData = await response.json();
                rData = rData.filter(x => x.visible === true);
                // parse tags
                var t = [];
                rData.forEach(a =>a.tags.forEach(b => { if(!t.includes(b)) t.push(b); }));
                setTags(t.map(a =>  ({name:a, enabled:false})));
                // set base data and grid data
                setData(rData.sort(sort_init));
            }
            catch(e){console.log(e)}
        }
    }

    const getFilteredData = (tagArray,searchString) => data.filter(x => 
        tagArray.every(value => x.tags.includes(value)) && 
        x.name.toLowerCase().includes(searchString.toLowerCase())
    );

    const handleSearch = (value) => {
        var tagArray = tags.filter(x => x.enabled).map(x => x.name);
        setGrid(getFilteredData(tagArray, value));
        setSearch(value);
    }

    const toggleTag = (name) => {
        // modify incoming value
        const tagsCopy = [...tags];
        const tagsIndex = tagsCopy.findIndex(x => x.name === name);
        const tagCopy = {...tagsCopy[tagsIndex]};
        tagCopy.enabled = !tagCopy.enabled;
        tagsCopy[tagsIndex] = tagCopy;

        // apply tag filter on grid
        var tagNames = tagsCopy.filter(x => x.enabled).map(v => v.name);
        setGrid(getFilteredData(tagNames,search));
        // update tags object
        setTags(tagsCopy);
    }

    return (
        <div className='height-stretch'>
            
            <div className='navbar-height' />
 
            <div className='height-grow width-fit box box-opacity Y-margin'>

            <Helmet htmlAttributes={{ lang: 'sv' }}>
                <title>BRG Grönkålen - Information</title>
                <meta name="keywords" content="BRF,Grönkålen,Bostadsrättsförening,Mäklare,Information,Boende,Blanketter" />
            </Helmet>


                <h1>INFORMATION {Credentials.isValid() && <button className='icon edit' onClick={() => navigate("/redigera-information")} />}</h1>

                <div className='info-tools'>

                    <input type='text' placeholder='Sök' value={search} onChange={(e) => {handleSearch(e.target.value)}} />

                    <div className='info-tags'>
                        <ul>
                            {tags.map((item, ix) => 
                            <li className={item.enabled ? 'tag enabled' : 'tag disabled'} onClick={() => toggleTag(item.name)} key={ix}>{item.name}</li>
                            )}
                        </ul>
                    </div>

                </div>

                <div className='info-grid table'>
                    <div className='row header'>
                        <div className='col1'> 
                            <span onClick={() => handleSort('file')}>
                                FILNAMN {sort.name === 'file' ? (sort.direction ==='desc' ? '🔼' : '🔽') : null }
                            </span> </div>
                        <div className='col2'>KATEGORI</div>
                        <div className='col3'>
                            <span onClick={() => handleSort('date')}>
                                DATUM {sort.name === 'date' ? (sort.direction ==='desc' ? '🔼' : '🔽') : null }
                            </span></div>
                        <div className='buttons' />
                    </div>
                    {grid.map((item, ix) => 
                    <div className='row item' key={ix} style={ix >0 ? {borderTop:'1px solid lightgray'} :null}>
                        <div className='col1'>{item.name}</div>
                        <div className='col2'>{item.tags.map((t,tx) => <span key={tx} className='minitag'>{t}</span>)}</div>
                        <div className='col3'>{isoDateString(new Date(item.modified)).substring(0,10)}</div>
                        <div className='buttons'><a href={'/'+item.url} download><button>Download</button></a></div>
                    </div>
                    )}
                </div>

            </div>

            <Footer />

        </div>
    );
}