import React,{useState,useEffect} from 'react'
import {Helmet} from "react-helmet-async"
import { useNavigate } from 'react-router-dom'
import {JSON_ABOUT} from '../config'
import Footer from '../nav/Footer'
import {Credentials} from '../login/fn_login'
import './about.css'


export default function About(){
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(()=> {
        getData();
    },[]);

    const getData = async () => {
        const sortDesc = (a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime();}
        const response = await fetch(JSON_ABOUT,{cache:'no-cache'});
        if(response.ok)
        {
            try{
                const rData = await response.json();
                setData(rData.filter(x => x.visible === true).sort(sortDesc));
            }
            catch(e) {console.log(e)}
        }
        
    }
    
    return (
        <div className="about">

            <Helmet htmlAttributes={{ lang: 'sv' }}>
                <meta charSet="utf-8" />
                <title>BRF Grönkålen</title>
                <link rel="canonical" href="https://gronkalen.se/brf-gronkalen" />
                <meta name="keywords" content="huvudsta,solna,BRF GRÖNKÅLEN,EKONOMISKT TRYGGT,BARNVÄNLIGT,NÄRA TILL MYCKET,Bostadsrättsförening,Jonstorpsvägen,Barnvänligt,Området,Områdets Historia" />
                <meta name="description" content='BRF Grönkålen - Bostadsrättsförening i Stockholm, Solna' />
            </Helmet>

            <img src='/images/gronkalen2.jpg' className='welcome-layout1-image anim' alt='BRF Grönkålen' title='Jonstorpsvägen' />
            
            {/* CONTENT */}
            <div className='welcome-layout1-bottom width-fit'>
                <div className='brag'>
                    <div>
                            <img src='/images/brag-money.png' alt='ekonomiskt tryggt' title='jonstorpsvägen' style={{pointerEvents:'none'}} />
                            <div>
                                <h3>EKONOMISKT TRYGGT</h3>
                                <span>God ekonomi i föreningen<br />Vi äger vår egen mark</span>
                            </div>
                    </div>
                    <div>
                        <img src='/images/brag-heart.png' alt='barnvänligt' title='jonstorpsvägen' style={{pointerEvents:'none'}} />
                         <div>
                            <h3>BARNVÄNLIGT</h3>
                            <span>Nära till flera lekplatser<br />Nära till förskolor</span>
                        </div>
                    </div>
                    <div>
                        <img src='/images/brag-map.png' alt='stockholm' title='jonstorpsvägen' style={{pointerEvents:'none'}} />
                        <div>
                            <h3>NÄRA TILL MYCKET</h3>
                            <span>Gå eller cykla på några minuter<br />Tunnelbana, Solna C, Sundbyberg</span>
                        </div>
                    </div>
                </div>
            </div>

                <h1>BRF GRÖNKÅLEN {Credentials.isValid() && <button className='icon edit' onClick={() => navigate("/redigera-brf-gronkalen")} />}</h1>

                {data.map((item, iIndex) => 
                <div className='box box-white box-border B-margin width-fit text' key={iIndex}>
                    <h2>{item.title}</h2>
                    <div>{ item.body.split('\n').map((row, rIndex) => <p key={rIndex}>{row}</p>)}</div>
                </div>
                )}

            <Footer />

        </div>
    );
}