import React, {useState, useEffect} from 'react'
import {Helmet} from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import './news.css'
import Footer from '../nav/Footer'
import {JSON_NEWS} from '../config'
import {Credentials} from '../login/fn_login'

export default function News(){
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=> { 
        get_data();
    } ,[]);

    //useEffect(() => );

    const SORT_NEWS = (a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime();}

    // get news from api
    const get_data = async () => {
        if(!loading) setLoading(true);
        fetch(JSON_NEWS,{cache:'no-cache'})
        .then(promise => {
            if(promise.ok)
                promise.json().then(data => setData(data.filter(x => x.visible === true).sort(SORT_NEWS)))
            else
                console.log("Error: faild to parse news.json");
        })
        .catch(error => console.log("News fetch error: ",error))
        .finally(setLoading(false));
    }

    return (
    <div className='height-stretch'>
        
        <Helmet htmlAttributes={{ lang: 'sv' }}>
            <title>BRF Grönkålen - Nyheter</title>
            <meta name="keywords" content="BRF,Grönkålen,Bostadsrättsförening,Jonstorpsvägen,Nyheter" />
        </Helmet>

            <div className='navbar-height' />

            {/* FLEXIBLE CONTENT SIZE */}
            <div className='box box-opacity Y-margin width-fit'>

                <h1>NYHETER {Credentials.isValid() && <button className='icon edit' onClick={() => navigate("/redigera-nyheter")} />}</h1>


                    {data.map((item, index) => 
                    
                    <div key={index} className='box box-white X-margin B-margin box-border'>
                        <div className='title text' style={{paddingBottom:0}}>
                            <h2>{item.title}</h2>
                            <div>{item.date}</div>
                        </div>
                        
                        <div className='text' style={{paddingTop:0}}>
                            {item.body.split('\n').map((row,row_index) => 
                                <p key={row_index}>{row}</p>
                            )}
                        </div>

                        {item.image && 
                        <div className='news-image'>
                            <img src={item.image} alt='Grönkålen' title='Jonstorpsvägen'  />
                        </div>}

                    </div>
                    
                    )}

            </div>

            <Footer />

    </div>);
}