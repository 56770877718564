import React from 'react'
import './Footer.css'
import data from './settings.json'
import { Link } from 'react-router-dom'

export default function Footer(){
    const logo = './logo192.png';

    return (
        <div className='footer wrapper'>
            <div className='footer content'>
                {/* LOGO-INFO */}
                <div className='footer logo'>
                    <img src={logo} alt='peter astner' title='astner' />
                    <div className='footer info'>
                        <div className='title'>{data.title}</div>
                        <div className='contact'>{data.email}</div>
                    </div>
                    
                </div>

                {/* LINKS */}
                
                <div  className='footer links'>
                    Länkar
                    <ul>
                    {
                        data.links.map((itm,ix) => 
                        <li key={ix}>
                            <Link to={itm.to}>
                                <span className='default'>
                                    {itm.name}
                                </span>
                            </Link>
                             <span className='divider'>/</span> 
                            <Link to={itm.to}>
                                <span className='small'>{itm.name}</span>
                            </Link>
                        </li>
                        )
                    }
                </ul>
                </div>
                
            </div>
        </div>
    );
}