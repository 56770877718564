import React,{useState,useEffect,useRef} from 'react'
import {isoDateString} from '../editor/functions'
import { JSON_FILES, API_URL } from '../config';
import Footer from '../nav/Footer'
import './info.css'
import { useNavigate } from 'react-router-dom';
import Loader from '../editor/loader';
import {Credentials} from '../login/fn_login'

const Form = ({handleItem, existingTags, returnHandler}) => {
    const [tags,setTags] = useState([]);
    const [newTag,setnewTag] = useState('');
    const [file,setFile] = useState(null);
    const [item,setItem] = useState({id:new Date().getTime(), visible:true, tags:[], modified:isoDateString(new Date()), name:"",url:""});
    const fileRef = useRef();

    // Setup form on first load
    useEffect(() => {
        setItem((a) => {
            var b = {...a};
            Object.keys(b).forEach(key => {
                if(Object.keys(handleItem).includes(key))
                    b[key] = (key === 'tags' ? [...handleItem[key]] : handleItem[key]); // why? > array is mutual object!
            });
            if(isNaN(b["id"])) b["id"] = new Date().getTime(); 
            return b;
        });
    },[handleItem]);

    useEffect(() => {setTags([...existingTags]);},[existingTags]);
    

    const handleInput = (key, value) => {
        if(["name","url","visible"].includes(key))
            setItem((a) => { var b= {...a};b[key] = value;return b; })
        if(key === 'tags')
        {
            var a = {...item};
            if(a.tags.includes(value))
                a.tags.splice(a.tags.indexOf(value),1);
            else
                a.tags.push(value);
            setItem(a);
        }
    }

    const handleSave = () => {
        var a = {...item};
        a.modified = new Date().getTime();
        returnHandler(a, file);
    }

    const addNewTag = () => {
        const newTagName = newTag;
        setTags((t) => {
            var t2 = [...t];
            t2.push(newTagName);
            return t2;
        });
        setnewTag('');
        handleInput('tags', newTagName);
    }

    const newTagCondition = newTag.length === 0 || tags.map(x => x.toLowerCase()).includes(newTag.toLowerCase());

    return(
        <div className='form'>
            <h3>Redigera Fil</h3>
            <div className='form-content'>
                <div>
                    <input type='file' ref={fileRef} hidden onChange={(e) => {setFile(e.target.files[0]);handleInput('name',e.target.files[0].name);handleInput('url','')}} />
                    <button onClick={() => fileRef.current.click()} style={{marginRight:10}}>Välj Fil</button>
                    {item.name && <span className='form-file'>{item.name || ''}</span>}
                    <br /><input type='checkbox' checked={item.visible || false} onChange={(e) => handleInput('visible', e.target.checked)} /> Synlig
                    <p style={{fontSize:'12px'}}>
                    Created: {isoDateString(new Date(item.id))}<br />
                    Modified: {isoDateString(new Date(item.modified))}
                    </p>
                </div>

                <div className='info-tags form-tags'>
                    <input type='text' value={newTag} onChange={(e)=> setnewTag(e.target.value)} /><button onClick={() => addNewTag()} disabled={newTagCondition}>Ny Tagg</button>
                    <br /><div className='header'>Tagga filen</div>
                    <ul>
                        {tags.map((a,b) => <li key={b} className={item.tags.includes(a) ? 'tag enabled' : 'tag disabled'} onClick={() => handleInput('tags', a)}>{a}</li>)}
                    </ul>
                </div>

            </div>

            <div className='buttons'>
                <button onClick={() => handleSave()} disabled={!item.name}>Spara</button>
                <button onClick={() => returnHandler()}>Avbryt</button>
            </div>
        </div>
    );

}

export default function View(){
    const navigate = useNavigate();
    const [load,setLoad] = useState(false);
    const [data,setData] = useState([]);
    const [grid,setGrid] = useState([]);
    const [mainTags,setMainTags] = useState([]);
    const [editItem,setEditItem] = useState({});
    const [search,setSearch] = useState('');

    useEffect(()=> {
        if(!Credentials.isValid()) navigate("/login");
        getData();
    },[]);

    // triggered upon *setData*
    useEffect(()=> {
        var t = [];
        data.forEach(a => {
            a.tags.forEach(tag => {if(!t.includes(tag)) t.push(tag);})
        });
        setMainTags(t);
        applyFilters();
    },[data]);

    const getData = async () => {
        const response = await fetch(JSON_FILES,{cache:'no-cache'});
        if(response.ok)
        {
            try{
                var responseData = await response.json();
                setData(responseData);
            }
            catch(e) {console.log(e)}
        }
        else
            console.log("could not fetch file dataset");
        setLoad(false);
    }

    const handleDelete = async (id) => {
        setLoad(true);
        const fd = new FormData();
        fd.append('jsonUrl', JSON_FILES);
        const response = await fetch(API_URL + "/files/" + id.toString(), {method:'delete',mode:'cors', body:fd});
        if(response.ok)
            getData();
        setLoad(false);
    }

    const handleSave = async (item, file) => {
        const fd = new FormData();
        fd.append('item', JSON.stringify(item));
        fd.append('jsonUrl',JSON_FILES);
        if(file !== null)
            fd.append('file', file);
        
        setLoad(true);
        const response = await fetch(API_URL + "/files", {method:'post',mode:'cors', body:fd});
        if(response.ok)
        {
            setEditItem({});
            getData();
        }
        else
            console.log("Failure saving upload item");
        setLoad(false);
    }


    const applyFilters = (searchStringOVerride = null) => {
        const filterItem = searchStringOVerride === null ? search.toLowerCase() : searchStringOVerride.toLowerCase();
        setGrid(data.filter(x => x.name.toLowerCase().includes(filterItem)));
    }


    const handleEdit = (item=null, file=null) => {
        if(item !== null) 
            handleSave(item, file);
        else
            setEditItem({});
    }

    const handleClicks = (event, id) => {
        if(event === 'new')
            setEditItem({id:new Date().getTime()});
        if(event === 'delete')
            handleDelete(id);
        if(event === 'edit')
        {
            setEditItem(() => {
                var b = grid.find(x => x.id === id);
                return {...b};
            });
        }
    }

    return (
        <div className="height-stretch">

            {load && <Loader />}

            <div className='navbar-height' />

            <div className='height-grow width-fit box box-opacity Y-margin'>

                <div className='info-c'>
                    <h1>HANTERA INFORMATION</h1>

                    <div className='box X-margin'>

                        {Object.keys(editItem).length > 0 && <Form existingTags={mainTags} handleItem={editItem} returnHandler={handleEdit} />}

                        <div className='search'>
                            {Object.keys(editItem).length === 0 && <button onClick={() => handleClicks('new', null)}>Ladda upp fil</button>}
                            <input type='text' placeholder='Sök' value={search} onChange={(e) => {setSearch(e.target.value);applyFilters(e.target.value)}} />
                        </div>

                     
                    </div>

                    <div className='info-grid table'>
                        <div className='row header'>
                            <div className='col1'>Namn</div>
                            <div className='col2'>Taggar</div>
                            <div className='buttons'></div>
                        </div>
                        {grid.map((a,i) => 
                            <div className={a.visible ? 'row' : 'row dark'} key={i} style={i>0 ? {borderTop:'1px solid lightgray'} : null}>
                                <div className='col1'>{a.name}</div>
                                <div className='col2'>{a.tags.map((t,ti) => <span key={ti} className='minitag'>{t}</span>)}</div>
                                <div className='buttons'>
                                    <button onClick={() => handleClicks('delete',a.id)}>Delete</button>
                                    <button onClick={() => handleClicks('edit',a.id)}>Edit</button>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>

            <Footer />

        </div>
    );
}