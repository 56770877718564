import React,{useState, useEffect,useRef} from 'react'
import Footer from '../nav/Footer'
import './editor.css'
import { hasKeyData,getDate, isoDateString,evalDateString, isNullOrEmpty,getFileExtension } from './functions'
import {Credentials} from '../login/fn_login'
import { useNavigate } from 'react-router-dom'
import Loader from './loader'


const sortDesc = (a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime();}


const Controller = ({itemToEdit, returnHandler}) => {
    const [item,setItem] = useState({"visible":true});
    const [image,setImage] = useState(null);
    const [checkDate,setCheckDate] = useState(true);
    const uploadRef = useRef();
    
    useEffect(() => {
        var eItem = {...itemToEdit};
        if(!hasKeyData(eItem,'visible'))
            eItem["visible"] = true;
        if(!hasKeyData(eItem, 'date'))
            eItem["date"] = isoDateString(getDate()).substring(0,10);
        if(!hasKeyData(eItem, 'id'))
            eItem["id"] = new Date().getTime();
        setItem(eItem);
    },[]);

    const modifyItem = (key,value) => {
        if(['title', 'body','date','visible','image'].includes(key))
            setItem(i => { var i2 = {...i};i2[key] = value;return i2; });
        
        if(key === 'date')
            setCheckDate(evalDateString(value));
    }

    return (
    <div className='controller content'>
        <h3>TOOLBAR</h3>
        <div className='toolbar'>
            <button disabled={!checkDate || !hasKeyData(item, 'title') || !hasKeyData(item,'body')} onClick={() => returnHandler(item,image)}>SAVE</button>
            <button onClick={() => returnHandler()}>CANCEL</button>
        </div>

        <div className='form'>
            <span>TITLE</span>
            <input type='text' value={item.title || ''} className='title' onChange={(e) => modifyItem('title',e.target.value)} />
            
            <span>DATE</span>
            <input type='text' style={checkDate ? null :{color:'red'}} value={item.date || ''} className='date' onChange={(e) => modifyItem('date',e.target.value)} />
            <input type='checkbox' checked={item.visible} onChange={(e) => modifyItem('visible',e.target.checked)} /> Visible
            
            <div>
                <span>IMAGE</span>
                {item.image && <button onClick={() => {modifyItem('image','');setImage(null);}}>REMOVE</button>}
                {!item.image && <button onClick={() => uploadRef.current.click()}>ADD</button>}
                <input type='file' ref={uploadRef} hidden onChange={(e) => {setImage(e.target.files[0]);modifyItem('image','new');}} />

            </div>

            <span>BODY</span>
            <textarea 
                className='body' 
                value={item.body || ''}  
                onChange={(e) => modifyItem('body',e.target.value)}
                spellCheck={false}
            />
        </div>
    </div>);
}


export default function View({title, apiUrl, jsonPath, uploadDir, uploadName}){
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [editItem,setEditItem] = useState({});
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if(!Credentials.isValid()) navigate("/login");

        if(!isNullOrEmpty(jsonPath))
            getData();
        else
            console.log("jsonFile is not valid");
    },[]);

    const getData = async () => {
        const response = await fetch(jsonPath, {cache:"no-cache"});
        if(response.ok)
        {
            try{
                const d = await response.json();
                setData(d.sort(sortDesc));
            }
            catch(e) {console.log(e)}
        }
        else
            console.log("Fetching data from jsonPath failed");
        setLoading(false);
    }

    const  dataIndex = (id) => {
        for(let i=0;i<data.length;i++)
            if(data[i].id === id)
                return i;
        return -1;
    }


    const saveData = async (item, image = null) => {
        setLoading(true);
        const fd = new FormData();
        fd.append("item", JSON.stringify(item));
        fd.append("jsonUrl", jsonPath);
        fd.append("uploadDir", uploadDir);
        if(image !== null)
            fd.append("file",image, `${uploadName}${item.id}${getFileExtension(image.name)}`);
        
        var response = await fetch(apiUrl, {method:'post',mode:'cors', body:fd});
        if(response.ok)
            await getData();
        else
            setLoading(false)
    }

    const deleteItem = async (id) => {
        setLoading(true);
        const fd = new FormData();
        fd.append('jsonUrl', jsonPath);
        const response = await fetch(apiUrl + `/${id}`, {method:'delete',mode:'cors', body:fd});
        if(response.ok)
            getData();
        else
            setLoading(false);
    }

    const editEvents = (item = null, image = null) => {
        if(item !==null)
            saveData(item, image);

        setEditItem({});
    }

    const handleClicks = (event, id = -1) => {
        if(event === 'edit')
            setEditItem(() => {
                const index = dataIndex(id);
                return {...data[index]};
            })
        
        if(event === 'show')
        {
            let index = dataIndex(id);
            var item = {...data[index]};
            item.visible = !item.visible;
            saveData(item);
        }

        if(event === 'delete')
            deleteItem(id);

        if(event === 'new')
            setEditItem({"id":new Date().getTime()});
    }

    return (
        <div className='height-stretch editor'>

            {loading && <Loader />}

            <div className='navbar-height' />

            <div className='width-fit height-grow box box-opacity Y-margin'>

                <h1>{title}</h1>
                
                {!Object.keys(editItem).length > 0 && 
                <div className='content'>

                    <h3>TOOLBAR</h3>
                    <div className='toolbar'>
                        <button onClick={() => handleClicks('new')}>NEW ITEM</button>
                    </div>

                    <h3>PAGE CONTENT</h3>
                    <div className='grid'>
                        <div className='row header'>
                            <div className='col1'>DATE</div>
                            <div className='col2'>TITLE</div>
                        </div>
                        {data.map((row, rindex) => 
                        <div className='row' key={rindex} style={rindex > 0 ? {borderTop:'1px solid black'} : null}>
                            <div className='col1'>{row.date}</div>
                            <div className='col2'>{row.title}</div>
                            <div className='col-buttons'>
                                <button onClick={() => handleClicks('edit',row.id)}>EDIT</button>
                                <button onClick={() => handleClicks('show',row.id)}>{row.visible ? 'HIDE' : 'SHOW'}</button>
                                <button onClick={() => handleClicks('delete',row.id)}>DELETE</button>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
                }

                {!!Object.keys(editItem).length && <Controller itemToEdit={editItem} returnHandler={editEvents} />}

            </div>
            
            <Footer />

        </div>
    );
}
