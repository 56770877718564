import React,{ useState,useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import {JSON_CONTACT} from '../config'
import {Credentials} from '../login/fn_login'
import Footer from '../nav/Footer'
import './contact.css'

export default function Contact(){
    const [data,setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    },[]);

    const getData = async () => {
        const sortDesc = (a,b) => { return new Date(b.date).getTime() - new Date(a.date).getTime();}
        const response = await fetch(JSON_CONTACT,{cache:'no-cache'});
        if(response.ok)
        {
            try
            {
                const rData = await response.json();
                setData(rData.filter(x => x.visible === true).sort(sortDesc));
            }
            catch(e) {console.log(e)} 
        }
        else
            console.log("Error: failed to get Contakt dataset");
    }

    return (
        <div className="height-stretch">

            <Helmet htmlAttributes={{ lang: 'sv' }}>
                <title>BRF Grönkålen - Kontakter</title>
                <meta name="keywords" content="BRF,Grönkålen,Kontakter,Styrelsen" />
            </Helmet>

            <div className="navbar-height" />

            <div className="width-fit height-grow box box-opacity Y-margin">

                <h1 className="center">KONTAKT {Credentials.isValid() && <button className="icon edit" onClick={() => navigate("/redigera-kontakt")} />}</h1>

                <a href="https://www.facebook.com/groups/2077397442570273/" target="_blank" className="facebook">
                    <img src='/images/facebook.png' />
                    <div>
                        <h2>Jonstorp i Huvudsta</h2>
                        <span>En facebook-grupp för oss som bor här</span>
                    </div>
                </a>

                <div className="contact-cards">
                    {data.map((item,ix) => 
                    <div className="box box-white text box-border X-margin" key={ix}>
                        <h3>{item.title}</h3>
                        <div>
                            {item.body.split('\n').map((row,rx) => <p key={rx}>{row}</p>)}
                        </div>
                    </div>
                    )}
                </div>

                    


            </div>

            <Footer />

        </div>
    );
}