import React from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {HelmetProvider } from 'react-helmet-async'
import './App.css'
import {API_URL, JSON_NEWS,JSON_ABOUT, JSON_CONTACT} from './pages/config'
// Pages
import Navbar from './pages/nav/Navbar'
import News from './pages/news/news'
import Contact from './pages/contact/contact'
import Login from './pages/login/login'
import About from './pages/about/about'
import Editor from './pages/editor/editor' 
import Info from './pages/info/info'
import InfoController from './pages/info/infoController'
import Accounts from './pages/login/accounts'
import Broker from './pages/broker/broker'



function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        
          <Navbar />
          <img src='/images/gronkalen.jpg' className='background-image-fixed brightness-high' alt='brf grönkålen' title='jonstorpsvägen' />

          <Routes>
            
            <Route exact path="/" element={<About /> } />
            <Route path="/nyheter" element={<News />} />
            <Route path="/redigera-nyheter" element={<Editor title={'REDIGERA NYHETER'} apiUrl={API_URL + '/page'} jsonPath={JSON_NEWS} uploadDir={'/files'} uploadName={'nyheter_'} />} />
            
            <Route path="/brf-gronkalen" element={<About />} />
            <Route path="/redigera-brf-gronkalen" element={<Editor title={'REDIGERA BRF GRÖNKÅLEN'} apiUrl={API_URL + '/page'} jsonPath={JSON_ABOUT} uploadDir={'/files'} uploadName={'om_oss_'} />} />

            <Route path="/kontakt" element={<Contact />} />
            <Route path="/redigera-kontakt" element={<Editor title={'REDIGERA KONTAKT'} apiUrl={API_URL + '/page'} jsonPath={JSON_CONTACT} uploadDir={'/files'} uploadName={'kontakt_'} />} />
            
            <Route path="/information" element={<Info />} />
            <Route path="/redigera-information" element={<InfoController />} />

            <Route path="/login" element={<Login />} />
            <Route path="/accounts" element={<Accounts />} />

            <Route path="/maklare" element={<Broker />} />

          </Routes>

      </BrowserRouter>
      </HelmetProvider>
  );
}

export default App;
