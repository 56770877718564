import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import Loader from '../editor/loader';
import Footer from '../nav/Footer';
import {Credentials} from './fn_login'
import './accounts.css';



export default function Accounts(){
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState([]);
    const [id,setId] = useState(-1);
    const [user,setUser] = useState('');
    const [pwd,setPwd] = useState('');
    const navigate = useNavigate();

    useEffect(() => { 
        if (!Credentials.isValid()) navigate('/login');
        getData()
     },[]);


     const getData = async () => {
        const token = Credentials.token();
        const response = await fetch(API_URL + `/accounts/${token}`, {method:'get', mode:'cors', headers:{"ContentType":"application/json"}});
        if(response.ok)
        {
            var rdata = await response.json();
            setData(rdata);
        }
        else
            console.log("REST.fetch-accounts: faild");
        setLoading(false);
     }


    const deleteUser = async (id) => {
        setLoading(true);
        const token = Credentials.token();
        const bodyJson = JSON.stringify({"token":token, "id":id});
        const response = await fetch(API_URL + "/accounts", {method:'delete', mode:'cors', body:bodyJson, headers:{"Content-Type":"application/json"}});
        if(response.ok)
            getData();
        else
        {
            console.log("Error could not delete");
            setLoading(false);
        }
    }
   
   
    const saveUser = async () => {
        setLoading(true);
        const token = Credentials.token();
        const item = JSON.stringify({id:id, user:user, password:pwd, role:'admin'});
        const fd = new FormData();
        fd.append('token',token);
        fd.append('item',item);
        const response = await fetch(API_URL + "/accounts", {method:'post',mode:'cors', body:fd});
        if(response.ok)
        {
            changeUser(-1);
            getData();
        }
        else
        {
            console.log("Something went wrong");
            setLoading(false);
        }
    }

    const changeUser = (id) => {
        setId(id);
        const u = data.find(x => x.id === id);
        u === undefined ? setUser('') : setUser(u.user);
        u === undefined ? setPwd('') : setPwd(u.password);
    }

    const failInput = user.trim().length === 0 || pwd.trim().length === 0 || data.find(x => x.user === user && x.id !== id) !== undefined;

    return (
        <div className='accounts height-stretch' style={{color:'white'}}>
            
            <div className='navbar-height' />

            <div className='height-grow width-fit box box-opacity Y-margin'>
                <h1>KONTON</h1>


                {/* GRID */}
                <div className='user-grid'>
                    
                    {/* HEADER ROW */}
                    <ul className='header'>
                        <li className='col1'>Name</li>
                        <li className='col2'>Password</li>
                        <li className='col3'><button onClick={()=> changeUser(new Date().getTime())}>NEW</button></li>
                    </ul>

                    {/* NEW FORM - only show if userId not in 'data' */}
                    {id !== -1 && data.find(x => x.id === id) === undefined && <ul className='edit-row'>
                        <li className='col1'><input type='text'  value={user} onChange={(e) => setUser(e.target.value)} placeholder='User' /></li>
                        <li className='col2'><input type='text' value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder='Password' /></li>
                        <li className='col3'>
                            <button onClick={() => saveUser()} disabled={failInput}>SAVE</button>
                            <button onClick={() => changeUser(-1)}>CANCEL</button>
                        </li>
                    </ul>}

                    {/* PRINT DATA AS GRID-ROWS */}
                    {data.map((item, index) =>
                    <ul className={item.id === id ? 'edit-row' : 'row'} key={index} style={index > 0 ? {borderTop:'1px solid lightgray'} : null}>
                        <li className='col1'>
                            {item.user}
                        </li>
                        <li className='col2'>
                        {id === item.id ?
                                <input type='text' value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder='Password' />
                                : '***********'
                            }
                        </li>
                        <li className='col3'>
                            {item.user !== Credentials.user() && item.id !== id && <button onClick={() => deleteUser(item.id)}>DELETE</button>}
                            {item.id !== id && <button onClick={() => changeUser(item.id)}>EDIT</button>}
                            
                            {item.id === id && <button onClick={() => saveUser()} disabled={failInput}>SAVE</button>}
                            {item.id === id && <button onClick={() => changeUser(-1)}>CANCEL</button>}
                        </li>
                    </ul> 
                )}

                </div>

            </div>
        
            <Footer />            

        </div>
    );
}