import React,{useState} from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
// Page libs
import {API_URL,WEBID} from '../config'
import './login.css'
import Footer from '../nav/Footer'
import {LoginDialogue} from './fn_login'

export default function Login(){
    return (
    <div className='height-stretch'>
            
        <Helmet htmlAttributes={{ lang: 'sv' }}>
            <title>BRF Grönkålen - Login</title>
            <meta name="keywords" content="BRF,Grönkålen,Login" />
        </Helmet>

        <div className='height-grow login'>

            <div className='form'>
                <LoginDialogue restricted={true} apiUrl={API_URL} webId={WEBID} redirect={'/nyheter'} />
            </div>
            
        </div>
        
        <Footer />

    </div>
    )
}